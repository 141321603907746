import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
  const data = useStaticQuery(
    graphql`
      query {
        heroImg: file(relativePath: { eq: "hero-img.png" }) {
          publicURL
        }
         reviews{
              reviewCount
                   }
        schemaLogo: file(relativePath: { eq: "logo.png" }) {
          publicURL
        }
      }
    `
  );
const reviewCount = data.reviews.reviewCount; 
  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "Electrician",
    "@id": "https://electrician.primesparks.co.uk",
    name: "Prime Sparks",
    legalName: "Roger Prime t/a Prime Sparks",
    description: "Your local Eastbourne Electrician",
    url: "https://www.primesparks.co.uk",
    priceRange: "$$",
    areaServed: [
      {
        "@type": "City",
        name: "Eastbourne",
        sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
      },
    ],
    email: "info@primesparks.co.uk",
    currenciesAccepted: "GBP",
    numberOfEmployees: "1",
    location: {
      "@type": "City",
      name: "Eastbourne",
      sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
    },
    foundingLocation: {
      "@type": "City",
      name: "Eastbourne",
      sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
    },
    slogan: "Your local electrician",
    telephone: "+447549528456",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Eastbourne",
      addressRegion: "East Sussex",
      postalCode: "BN23 6BT",
      streetAddress: "22 Frobisher Close",
      addressCountry: "United Kingdom",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "0.31791",
      longitude: "50.78645656580949",
    },
    hasMap: "https://goo.gl/maps/7pkmWEu2HcKaxJx68",
    openingHours: "Mo,Tu,We,Th,Fr,Sa,Su 08:00-17:30",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Monday",
        opens: "08:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Tuesday",
        opens: "08:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Wednesday",
        opens: "08:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Thursday",
        opens: "08:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Friday",
        opens: "08:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Saturday",
        opens: "08:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        closes: "17:30",
        dayOfWeek: "http://schema.org/Sunday",
        opens: "08:00",
      },
    ],
    founder: {
      "@type": "Person",
      name: "Roger Prime",
      id: "https://www.primesparks.co.uk/about#rogerprime",
      url: "https://www.primesparks.co.uk/about",
      description:
        "Roger is an experienced, highly-qualified and expert electrician who is passionate about his job. After  completing an apprenticeship for a local family-run electrical company in Sussex, he was one of the  few apprentices who showed enough promise and skill to be asked to stay on as a full-time  employee. Keen to improve his skill set and further his career, Roger accepted the offer and ended up staying on as a full-time employee for 15 years.",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      bestRating: "5",
      worstRating: "1",
      ratingValue: "4.9",
      reviewCount: `${reviewCount}`,
      url: "https://www.primesparks.co.uk/testimonials",
    },
    contactPoint: {
      "@type": "ContactPoint",
      "@id": "https://www.primesparks.co.uk/contact-us#contactform",
      url: "https://www.primesparks.co.uk/contact-us",
      image: `${data.schemaLogo.publicURL}`,
      name: "Electrician Contact Form",
      description: "",
      telephone: "+447549528456",
      contactType: "Electrical Services",
      availableLanguage: "en",
      areaServed: [
        {
          "@type": "City",
          name: "Eastbourne",
          sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
        },
      ],
    },
    logo: `${data.schemaLogo.publicURL}`,
    image: `${data.heroImg.publicURL}`,
    sameAs: [
      "https://www.facebook.com/primesparkselec",
      "https://www.linkedin.com/in/roger-prime-b17023188",
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
    </Helmet>
  );
};

export default LocalBusinessSchema;
