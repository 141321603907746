import React from "react";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";

const PageContact = ({ image }) => {
	const pluginImage = getImage(image);
	const [state, handleSubmit] = useForm("xjvjjdoa");
	if (state.succeeded) {
		return (window.location = "/thank-you");
	}

	return (
		<>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<BgImage image={pluginImage} className="">
				<Container>
					<Row
						className="text-center text-white align-items-center justify-content-center "
						style={{ minHeight: "100vh" }}
					>
						<Col md={8} lg={6}>
							<h3>Get In Touch</h3>
							<hr className="  bg-white mb-5" />
							<div className="bg-white p-md-4">
								<Form className="w-100 text-right p-3" onSubmit={handleSubmit}>
									<Form.Group controlId="name">
										<Form.Control name="name" placeholder="Name" type="text" />
									</Form.Group>
									<ValidationError
										prefix="Name"
										field="name"
										errors={state.errors}
									/>
									<Form.Group controlId="email">
										<Form.Control
											name="_replyto"
											type="email"
											placeholder="Email Address"
										/>
									</Form.Group>
									<ValidationError
										prefix="Email"
										field="email"
										errors={state.errors}
									/>
									<Form.Group controlId="telephone">
										<Form.Control
											type="tel"
											name="telephone"
											placeholder="Telephone Number"
										/>
									</Form.Group>

									<ValidationError
										prefix="Telephone"
										field="telephone"
										errors={state.errors}
									/>
									<Form.Group controlId="message">
										<Form.Control
											name="message"
											placeholder="Message"
											as="textarea"
											rows={3}
										/>
									</Form.Group>

									<ValidationError
										prefix="Message"
										field="message"
										errors={state.errors}
									/>
									<Row>
										<Col>
											<div
												className="g-recaptcha ml-auto float-right pb-3"
												data-sitekey="6LeueHkcAAAAANckPYFD7yVOG3oyQ7I8jmIDUWB9"
											></div>
										</Col>
									</Row>

									<Row>
										<Col>
											<Button
												className="btn-small btn-primary mt-0 text-white "
												type="submit"
												id="contact-send-btn"
												disabled={state.submitting}
											>
												Send
											</Button>
										</Col>
									</Row>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</BgImage>
		</>
	);
};

export default PageContact;
