import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BgImageCta from "../components/bg-image-cta";
import CtaBanner from "../components/cta-banner";
import DotsCta from "../components/dots-cta";
import Testimonials from "../components/testimonials";
import Link from "../components/link";
import Certifications from "../components/certifications";
import Hero from "../components/hero";
import PageContact from "../components/page-contact";
import Messaging from "../components/messaging";
import { StaticImage } from "gatsby-plugin-image";

import { GatsbySeo } from "gatsby-plugin-next-seo";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "../components/footer";

import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "hero-img.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: FULL_WIDTH
						placeholder: BLURRED
					)
				}
			}
			pierImg: file(relativePath: { eq: "pier.png" }) {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: FULL_WIDTH
						placeholder: BLURRED
					)
				}
			}

			airSparkImg: file(relativePath: { eq: "why-prime-sparks.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: FULL_WIDTH
						placeholder: BLURRED
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const schemaImg = data.heroImg.publicURL;
	const airSparkImg = data.airSparkImg.childImageSharp.gatsbyImageData;
	const pierImg = data.pierImg.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Messaging />
			<CookieBanner disableUrl />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Electrician in Eastbourne | Prime Sparks"
				description="We offer a unique and personalised service to East Sussex and the surrounding areas, delivering the highest level of excellence to meet your domestic and commercial needs."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Electrician in Eastbourne | Prime Sparks",
					description: "Your Local Electrician",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Prime Sparks: Electrician in Eastbourne ",
						},
					],
				}}
			/>
			<Hero backgroundImage={heroImg} />
			<section id="introduction">
				<DotsCta
					sHeading="Electrician in Eastbourne"
					heading={
						<span>
							{" "}
							Looking for a <span className="text-primary">local</span>{" "}
							electrician you can trust?
						</span>
					}
					text=" If you’re looking for a reliable, local electrician in
                    Eastbourne to carry out an electrical installation, repair
                    or replacement, then relax, you’ve come to the right place… 
                    With nearly 20 years’ experience as an electrician in East
                    Sussex you can rest assured that Prime Sparks will provide
                    you with a excellent, on-time service completed to the
                    highest industry standard. "
					btnText="Get in Touch"
					btnUrl="/contact-us"
				/>
			</section>
			<section id="services" className="pt-5 pt-lg-0">
				<Container>
					<h3 className="text-center">
						<span className="text-primary">Our</span> Services
					</h3>

					<hr className=" bg-primary mb-3 mt-4" />
					<Row className="py-6 mb-5">
						<Col className="text-center" md={6} lg={3}>
							<Link
								to="/services/electric-vehicle"
								className="hover-no-decoration"
							>
								<div className="service-icon bg-primary  justify-content-center mx-auto">
									<Row className=" h-100 align-items-center">
										<Col>
											<StaticImage
												quality="100"
												src="../images/icons/electric-vehicle.svg"
												alt="electric-vehicle"
												placeholder="blurred"
												width={90}
												formats={["auto", "webp"]}
											/>
										</Col>
									</Row>
								</div>

								<p className="pt-4 service-icon-text">Electric Vehicle</p>
							</Link>
						</Col>
						<Col className="text-center pt-5 pt-md-0" md={6} lg={3}>
							<Link to="/services/domestic" className="hover-no-decoration">
								<div className="service-icon bg-primary  justify-content-center mx-auto">
									<Row className=" h-100 align-items-center">
										<Col>
											<StaticImage
												quality="100"
												src="../images/icons/domestic.svg"
												alt="domestic"
												placeholder="blurred"
												width={90}
												formats={["auto", "webp"]}
											/>
										</Col>
									</Row>
								</div>
								<p className="pt-4 service-icon-text">Domestic</p>
							</Link>
						</Col>
						<Col className="text-center pt-5 pt-lg-0" md={6} lg={3}>
							<Link to="/services/commercial" className="hover-no-decoration">
								<div className="service-icon bg-primary  justify-content-center mx-auto">
									<Row className=" h-100 align-items-center">
										<Col>
											<StaticImage
												quality="100"
												src="../images/icons/commercial.svg"
												alt="commercial"
												placeholder="blurred"
												width={90}
												formats={["auto", "webp"]}
											/>
										</Col>
									</Row>
								</div>
								<p className="pt-4 service-icon-text">Commercial</p>
							</Link>
						</Col>
						<Col className="text-center pt-5 pt-lg-0" md={6} lg={3}>
							<Link to="/services/inspections" className="hover-no-decoration">
								<div className="service-icon bg-primary  justify-content-center mx-auto">
									<Row className=" h-100 align-items-center pr-2 pt-1">
										<Col>
											<StaticImage
												quality="100"
												src="../images/icons/inspections.svg"
												alt="inpections"
												placeholder="blurred"
												width={90}
												formats={["auto", "webp"]}
											/>
										</Col>
									</Row>
								</div>
								<p className="pt-4 service-icon-text">Inspections</p>
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
			<BgImageCta
				focus="center"
				vHeight="32rem"
				img={airSparkImg}
				heading={
					<span>
						Why use <span className="text-primary">Prime Sparks</span>?
					</span>
				}
				text="Simple. Not only will you receive friendly, professional service from a local electrician born, bred and based in Eastbourne, but all of the electrical work we undertake is fully guaranteed and always carried out to the highest possible professional standards, so once you have accepted the quote you can sit back, read the paper or enjoy a coffee while we will take care of the rest. "
				cta="CONTACT US"
				ctaUrl="/contact-us"
			/>
			<Certifications />
			<Testimonials />
			{/* <CtaBanner
				headline="Covid-Safe Electrician"
				text="Simple. Not only will you receive friendly, professional service from a local electrician born, bred and based in Eastbourne, but all of the electrical work we undertake is fully guaranteed and always carried out to the highest possible professional standards, so once you have accepted the quote you can sit back, read the paper or enjoy a coffee while we will take care of the rest. "
			/> */}
			{/* <StayConnected /> */}
			<PageContact image={pierImg} />
			<Footer />
		</>
	);
};

export default IndexPage;
