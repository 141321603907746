import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import Navigation from "./navigation";

const Hero = ({ title, description, type, backgroundImage }) => {
  const pluginImage = getImage(backgroundImage);

  return (
    <BgImage
      image={pluginImage}
      style={{ minHeight: "100vh" }}
      className="hero-services mb-3  "
    >
      <Navigation bgColor="none" variant="dark" />
      <Container>
        <Row
          style={{ height: "80vh" }}
          className="align-items-center pt-md-3  "
        >
          <Col xs={12} lg={8} md={12}>
            <h1 className=" text-white pb-md-5 pb-3" id="home-hero-headline">
              Your local and trusted electrician based in Eastbourne
            </h1>
            <p
              className="text-white font-weight-light pb-md-5 pb-3 montserrat-light"
              id="home-hero-subheading"
            >
              Prime Sparks provides reliable and efficient electrical services
              for domestic and commercial customers alike.
            </p>
            <Row>
              <Col xs={12} lg={5} xl={4} md={5} className="mb-3">
                {" "}
                <Button as={Link}  to="/contact-us" className=" cta-btn text-white">
                  Get in Touch
                </Button>
              </Col>
              <Col xs={12} lg={7} xl={8} md={7} className=" text-left ">
                <Button as={Link} className="cta-btn" variant="outline-primary" to="/#services">
                  Our Services
                </Button>
              </Col>
            </Row>
          </Col>
          <div className=" d-none  d-lg-block hero-social-icons ">
            <Link
              style={icon}
              target="_blank"
              className="hero-social-icon social-icon   "
              href="https://www.facebook.com/primesparkselec"
            >
              <FaFacebookSquare />
            </Link>
            <br />
            <Link
              style={icon}
              target="_blank"
              className=" hero-social-icon social-icon  "
              href="https://www.linkedin.com/in/roger-prime-b17023188"
            >
              <FaLinkedin />
            </Link>
          </div>
        </Row>
      </Container>
    </BgImage>
  );
};
const icon = {
  fontSize: "3rem",
};

export default Hero;
